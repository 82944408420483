// @ts-nocheck
import React, { useEffect, useState, useCallback } from "react";
import api from "../../api";
import { usePagination } from "../../hooks";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Layout from "../Layout";
import { generateGridData } from "./GenerateGridData";
import { DataGrid } from "../../partials/table/DataGrid";
import { useAppStore } from "../../AppStore";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import AddResellerModal from "../../partials/modal/AddResellerModal";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export const userRole = {
  admin: "Administrator",
  provider: "Service Provider",
  reseller: "Integrator",
};

export default function IntegratorsList() {
  const [VALUE] = useAppStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openAddIntegratorModal, setOpenAddIntegratorModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [filters, setFilters] = useState({
    role: null,
    parent_user_id: null,
  });
  const [gridData, setGridData] = useState({ gridColumns: [], gridRows: [] });
  const {
    pagination,
    paginationSizeHandler,
    paginationPageHandler,
    paginationAmountHandler,
  } = usePagination();

  const userRole = VALUE.currentUser.role;

  useEffect(() => {
    if (userRole !== undefined && userRole === "reseller") {
      history.push("/");
    }
  }, [userRole, history]);

  const handlerIntegratorModal = () => {
    setOpenAddIntegratorModal((prev) => !prev);
  };

  const getIntegratorsList = (page, rows, filters = {}) => {
    setShowLoader(true);
    api
      .post("/public/v2/users/", {
        filters: {
          role: filters.role,
          parent_user_id: filters.parent_user_id,
        },
        pagination: {
          pgoffset: page,
          pgsize: rows,
        },
      })
      .then(({ data }) => {
        if (!data) return;
        const { items, amount } = data;
        const dataForGrid = generateGridData(items);
        setGridData(dataForGrid);
        paginationAmountHandler(amount);
        setShowLoader(false);
      });
  };

  const handlerEnableChange = useCallback(
    (user_id, active) => {
      setShowLoader(true);
      api
        .patch(`/public/users/${user_id}`, {
          active: !active,
        })
        .then(() => {
          if (userRole === "admin") {
            getIntegratorsList(pagination.offset, pagination.size, filters);
          } else {
            getIntegratorsList(pagination.offset, pagination.size);
          }
          enqueueSnackbar("Success", {
            variant: "success",
            autoHideDuration: 3000,
          });
        });
    },
    // eslint-disable-next-line
    [getIntegratorsList, filters]
  );

  const showIntegrators = useCallback((parentId, name, lastName) => {
    let filters = {};
    filters.role = "reseller";
    filters.parent_user_id = parentId;
    filters.firstName = name;
    filters.lastName = lastName;
    setFilters(filters);
  }, []);

  const canShowIntegrators = Object.values(filters).every(
    (item) => item !== null
  );

  useEffect(() => {
    setShowLoader(true);
    if (userRole === "admin") {
      getIntegratorsList(pagination.offset, pagination.size, filters);
    } else {
      getIntegratorsList(pagination.offset, pagination.size);
    }
    // eslint-disable-next-line
  }, [pagination.offset, pagination.size, filters]);

  return (
    <Layout title="Users">
      <Box p={2} width={1}>
        <Grid item xs={12}>
          <Paper>
            <Box p={3}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  {canShowIntegrators && (
                    <Tooltip title="Back">
                      <IconButton
                        size="medium"
                        onClick={() =>
                          setFilters({ role: null, parent_user_id: null })
                        }
                      >
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Typography align="left" variant="h3">
                    {canShowIntegrators
                      ? `${filters.firstName} ${filters.lastName}`
                      : "Users List"}
                  </Typography>
                </Box>

                {!canShowIntegrators && (
                  <Button onClick={handlerIntegratorModal} color="primary">
                    ADD NEW USER
                  </Button>
                )}
              </Box>
              <DataGrid
                isLoading={showLoader}
                pagination={pagination}
                onSizeChange={paginationSizeHandler}
                onPageChange={paginationPageHandler}
                gridColumns={gridData.gridColumns.filter((item) =>
                  userRole === "admin" && !canShowIntegrators
                    ? item
                    : item.name !== "Role"
                )}
                gridRows={gridData.gridRows}
                handlerEnableChange={handlerEnableChange}
                data={gridData.gridRows.length > 0}
                showIntegrators={showIntegrators}
              />
            </Box>
          </Paper>
        </Grid>
        <AddResellerModal
          show={openAddIntegratorModal}
          handleClose={handlerIntegratorModal}
          getIntegratorsList={() =>
            getIntegratorsList(pagination.offset, pagination.size)
          }
          role={userRole}
        />
      </Box>
    </Layout>
  );
}
