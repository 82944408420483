// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import api from "../../api";
import { usePagination } from "../../hooks";
import { Box, Typography, Grid, Paper, Button } from "@material-ui/core";
import Layout from "../Layout";
import { generateGridData } from "./GenerateGridData";
import { DataGrid } from "../../partials/table/DataGrid";
import AddLicenseModal from "../../partials/modal/AddLicenseModal";
import { useAppStore } from "../../AppStore";
import { useSnackbar } from "notistack";
import DropDown from "../../partials/DropDown";
import { SearchFilters } from "../../partials/SearchFilters";
import { onlyNumbersValidation, validUUID } from "../../utils/validations";

export default function LicensesList() {
  const [VALUE] = useAppStore();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(true);
  const [openAddLicenseModal, setOpenAddLicenseModal] = useState(false);
  const [sortActivationDate, setSortActivationDate] = useState("activated_at");
  const [filters, setFilters] = useState({});
  // const [company, setCompany] = useState([]);
  const [value, setValue] = useState("");
  const [radio, setRadio] = useState("Company");
  const [gridData, setGridData] = useState({ gridColumns: [], gridRows: [] });
  const {
    pagination,
    paginationSizeHandler,
    paginationPageHandler,
    paginationAmountHandler,
  } = usePagination();

  const userRole = VALUE.currentUser.role;

  const handlerLicenseModal = () => {
    setOpenAddLicenseModal((prev) => !prev);
  };

  const handlerSort = (defaultValue, descValue) => {
    setShowLoader(true);
    setSortActivationDate((prev) =>
      prev === defaultValue ? descValue : defaultValue
    );
  };

  // eslint-disable-next-line
  // const getCompanyList = useMemo(() => {
  //   api
  //     .post("/public/v1/licenses/company/names/", {
  //       orderings: ["company_name"],
  //       pagination: {
  //         pgoffset: 0,
  //         pgsize: -1,
  //       },
  //     })
  //     .then(({ data }) => setCompany(data.items));
  // }, []);

  const getLicensesList = useCallback(
    (page, rows, sort, filters = {}) => {
      api
        .post("/public/v1/licenses/", {
          orderings: [sort],
          pagination: {
            pgoffset: page,
            pgsize: rows,
          },
          filters: {
            status_in: filters.status_in?.length > 0 ? filters.status_in : null,
            days_left_in:
              filters.days_left_in?.length > 0 ? filters.days_left_in : null,
            company_name_in:
              filters.company_name_in?.length > 0
                ? filters.company_name_in
                : null,
            company:
              radio === "Company" && value.length > 0
                ? value.toLowerCase()
                : null,
            company_contact:
              radio === "Company Contact" && value.length > 0
                ? value.toLowerCase()
                : null,
            contract:
              radio === "Contract" && value.length > 0
                ? value.toLowerCase()
                : null,
            license_key:
              radio === "Key (Complete Coincidence)" &&
              validUUID(value) &&
              value.length > 0
                ? value.toLowerCase()
                : null,
            license_id:
              radio === "License ID (Complete Coincidence)" &&
              onlyNumbersValidation(value) &&
              value.length > 0
                ? Number(value)
                : null,
          },
        })
        .then(({ data }) => {
          if (!data) return;
          const { items, amount } = data;
          const dataForGrid = generateGridData(items);
          setGridData(dataForGrid);
          paginationAmountHandler(amount);
          setShowLoader(false);
        });
    },
    [paginationAmountHandler, value, radio]
  );

  const handlerEnableChange = (license_id, is_enabled) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        is_enabled: !is_enabled,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("Success", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const handlerEditContractId = (license_id, value) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        contract_id: value.length ? value : null,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("Success", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const editDays = (license_id, days) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        days: days,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("The license has been successfully edited", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const editChannels = (license_id, cameras) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        cameras_count: cameras,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("The license has been successfully edited", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const extendActivatedExpired = (license_id, days) => {
    setShowLoader(true);
    api
      .post(`public/v1/licenses/${license_id}/extend/`, {
        days: days,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("License successfully extended", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const addChannelsActivatedExpired = (license_id, cameras_count) => {
    setShowLoader(true);
    api
      .post(`public/v1/licenses/${license_id}/extend/`, {
        cameras_count: cameras_count,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("Channels successfully added", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  // возможно лишний юз эффект, если все работает корректно - удалить
  // useEffect(() => {
  //   setShowLoader(true);
  //   // if (filters.status_in && filters.status_in.length === 0) {
  //   //   delete filters.status_in;
  //   // }
  //   // if (filters.days_left_in && filters.days_left_in.length === 0) {
  //   //   delete filters.days_left_in;
  //   // }
  //   // if (filters.company_name_in && filters.company_name_in.length === 0) {
  //   //   delete filters.company_name_in;
  //   // }
  //   if (!Object.keys(filters).length) {
  //     getLicensesList(
  //       pagination.offset,
  //       pagination.size,
  //       sortActivationDate,
  //       filters
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [pagination.offset, pagination.size, sortActivationDate, filters]);

  useEffect(() => {
    if (!!Object.keys(filters).length || value.length > 0) {
      paginationSizeHandler(0, pagination.size);
    }
    // eslint-disable-next-line
  }, [filters, value]);
  
  // возможно лишний юз эффект, если все работает корректно - удалить
  // useEffect(() => {
  //   if (
  //     Object.keys(filters).length > 0 &&
  //     filters.company_name_in?.length === 1
  //   ) {
  //     getLicensesList(0, pagination.size, sortActivationDate, filters);
  //   }
  //   // eslint-disable-next-line
  // }, [filters]);

  useEffect(() => {
    if (filters.status_in && filters.status_in.length === 0) {
      delete filters.status_in;
    }
    if (filters.days_left_in && filters.days_left_in.length === 0) {
      delete filters.days_left_in;
    }
    // if (filters.company_name_in && filters.company_name_in.length === 0) {
    //   delete filters.company_name_in;
    // }
    // if (Object.keys(filters).length > 0) {
    getLicensesList(
      pagination.offset,
      pagination.size,
      sortActivationDate,
      filters
    );
    // }
    // eslint-disable-next-line
  }, [pagination.offset, pagination.size, sortActivationDate, filters, value]);

  return (
    <Layout title="Licenses">
      <Box p={2} width={1}>
        <Grid item xs={12}>
          <Paper>
            <Box p={3}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography align="left" variant="h3">
                  Licenses List
                </Typography>
                <Box style={{ display: "flex", marginLeft: "auto" }}>
                  <SearchFilters
                    value={value}
                    setValue={setValue}
                    radio={radio}
                    setRadio={setRadio}
                  />
                  <Button
                    style={{ whiteSpace: "nowrap" }}
                    onClick={handlerLicenseModal}
                    color="primary"
                  >
                    Generate New License
                  </Button>
                </Box>
              </Box>
              <Box mb={2}>
                <DropDown
                  filters={filters}
                  setFilters={setFilters}
                  // companyList={company}
                />
              </Box>
              <DataGrid
                isLoading={showLoader}
                pagination={pagination}
                onSizeChange={paginationSizeHandler}
                onPageChange={paginationPageHandler}
                gridColumns={gridData.gridColumns.filter((item) =>
                  userRole === "admin" ? item : item.name !== "Account Manager"
                )}
                gridRows={gridData.gridRows}
                handlerEnableChange={handlerEnableChange}
                data={gridData.gridRows.length > 0}
                handlerSort={handlerSort}
                handlerEditContractId={handlerEditContractId}
                editDays={editDays}
                editChannels={editChannels}
                extendActivatedExpired={extendActivatedExpired}
                addChannelsActivatedExpired={addChannelsActivatedExpired}
                role={userRole}
                searchText={value}
              />
            </Box>
          </Paper>
        </Grid>
        <AddLicenseModal
          show={openAddLicenseModal}
          handleClose={handlerLicenseModal}
          user_id={VALUE.currentUser.id}
          getLicenses={() =>
            getLicensesList(
              pagination.offset,
              pagination.size,
              sortActivationDate
            )
          }
        />
      </Box>
    </Layout>
  );
}
