// @ts-nocheck

export const checkValidEmail = (valueInput) =>
  valueInput && valueInput.match(/^[\w-.]+@([\w-]+.)+[\w-]{2,12}$/);

export const passwordValid = (valueInput) =>
  valueInput &&
  valueInput.match(/[a-z]/g) &&
  valueInput.match(/[A-Z]/g) &&
  valueInput.match(/[0-9]/g) &&
  valueInput.match(/[^a-zA-Z\d]/g) &&
  valueInput.length >= 8 &&
  valueInput.length <= 99;

export const userValid = (valueInput) =>
  valueInput.match(/[A-Za-z]/g) &&
  !valueInput.match(/[0-9]/g) &&
  !valueInput.match(/[^a-zA-Z\d\s-]/g) &&
  valueInput.length >= 2;
// /^[^ ]+(?:\s[^ ]+)*$/
export const CompanyNameValid = (valueInput) =>
  // valueInput && valueInput.match(/^[0-9A-Za-z_\-\s]{1,120}$/g);
  valueInput && valueInput.length >= 2 && valueInput.length <= 120 &&
  valueInput.match(/^[^ ]+(?:\s[^ ]+)*$/g);

export const onlyNumbersValidation = (valueInput) =>
  valueInput && valueInput.match(/^[1-9]\d*$/);

export const timeConverter = (UNIX_timestamp) =>
  new Date(UNIX_timestamp).toLocaleDateString("en-US");

export const firstLetterUpperCase = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};
// от 1 до 100, только цифры
export const validDigitRange = (value) => value && value.match(/^[1-9]$|^[1-9][0-9]$|^(100)$/);

export const validUUID = (value) => value && value.match(/^(([0-9a-fA-F]{8})[-]([0-9a-fA-F]{4})[-]([0-9a-fA-F]{4})[-]([0-9a-fA-F]{4})[-]([0-9a-fA-F]{12}))$/gm);
