// @ts-nocheck
import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
} from "@material-ui/core";
import api from "../../api";
import {
  checkValidEmail,
  passwordValid,
  userValid,
  CompanyNameValid,
} from "../../utils/validations";
import { useSnackbar } from "notistack";
import BasicDialogue from "./BasicDialogue";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  select: {
    paddingLeft: 12,
  },
}));

const rolesList = [
  {
    name: "Service Provider",
    value: "provider",
  },
  {
    name: "Integrator",
    value: "reseller",
  },
];

export default function AddResellerModal({
  show,
  handleClose,
  getIntegratorsList,
  role,
}) {
  const [nameReseller, setNameReseller] = useState("");
  const [lastNameReseller, setLastNameReseller] = useState("");
  const [emailReseller, setEmailReseller] = useState("");
  const [password, setPassword] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [userRole, setUserRole] = useState("");

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const createReseller = () => {
    api
      .post("/public/users/", {
        first_name: nameReseller,
        last_name: lastNameReseller,
        email: emailReseller,
        password: password,
        company: CompanyName,
        role: role === "admin" ? userRole : "reseller",
      })
      .then(() => {
        getIntegratorsList();
        handleClearForm();
        enqueueSnackbar(
          "Success. New user have to confirm email address via clicking verification link in email",
          { variant: "success", autoHideDuration: 3000 }
        );
      });
  };

  const handleChangeRole = (event) => {
    setUserRole(event.target.value);
  };

  const handleClearForm = () => {
    setNameReseller("");
    setLastNameReseller("");
    setEmailReseller("");
    setPassword("");
    setCompanyName("");
    setUserRole("");
    handleClose();
  };

  const isCreatingDisabled = (name, lastName, company, email, pass) => {
    if (
      userValid(name) &&
      userValid(lastName) &&
      CompanyNameValid(company) &&
      checkValidEmail(email) &&
      passwordValid(pass)
    )
      return true;
    return false;
  };

  const Content = (
    <Grid container spacing={2} direction="column">
      <Grid item xs>
        <TextField
          name="first_name"
          size="small"
          fullWidth
          label="First Name"
          variant="outlined"
          value={nameReseller}
          onChange={(e) => setNameReseller(e.target.value)}
          error={!userValid(nameReseller) && nameReseller !== ""}
          helperText={
            !userValid(nameReseller) &&
            nameReseller !== "" &&
            "The First Name field cannot contain less than two letters, as well as special characters and numbers."
          }
        />
      </Grid>
      <Grid item xs>
        <TextField
          name="last_name"
          size="small"
          fullWidth
          label="Last Name"
          variant="outlined"
          value={lastNameReseller}
          onChange={(e) => setLastNameReseller(e.target.value)}
          error={!userValid(lastNameReseller) && lastNameReseller !== ""}
          helperText={
            !userValid(lastNameReseller) &&
            lastNameReseller !== "" &&
            "The First Name field cannot contain less than two letters, as well as special characters and numbers."
          }
        />
      </Grid>
      {role === "admin" ? (
        <Grid item xs>
          <FormControl style={{ width: "100%" }}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ zIndex: 1, paddingLeft: 12 }}
            >
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={userRole}
              onChange={handleChangeRole}
              classes={{ root: classes.select }}
              endAdornment={
                userRole !== "" && (
                  <Box mr="15px">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setUserRole("");
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                )
              }
            >
              {rolesList.map((item) => (
                <MenuItem key={item.name} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs>
          <TextField
            name="role"
            size="small"
            fullWidth
            label="Role"
            variant="outlined"
            value="Integrator"
            disabled
          />
        </Grid>
      )}
      <Grid item xs>
        <TextField
          name="Company name"
          size="small"
          fullWidth
          label="Company name"
          variant="outlined"
          value={CompanyName}
          onChange={(e) => setCompanyName(e.target.value)}
          helperText={
            !CompanyNameValid(CompanyName) &&
            CompanyName !== "" &&
            "The Company name must be 1-120 letters, and can contain space, hyphen, and the underline sign, cannot start or end with a whitespace or have more than 1 whitespace in a row"
          }
          error={!CompanyNameValid(CompanyName) && CompanyName !== ""}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name="email"
          size="small"
          fullWidth
          label="E-Mail"
          variant="outlined"
          type="mail"
          value={emailReseller}
          onChange={(e) => setEmailReseller(e.target.value)}
          helperText={
            !checkValidEmail(emailReseller) &&
            emailReseller !== "" &&
            "Wrong login format"
          }
          error={!checkValidEmail(emailReseller) && emailReseller !== ""}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name="password"
          size="small"
          fullWidth
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          helperText={
            !passwordValid(password) &&
            password !== "" &&
            "Password should be between 8-99 symbols, contain numbers, uppercase, lowercase and special characters"
          }
          error={!passwordValid(password) && password !== ""}
        />
      </Grid>
    </Grid>
  );

  const Action = (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleClearForm()}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={createReseller}
        disabled={
          !isCreatingDisabled(
            nameReseller,
            lastNameReseller,
            CompanyName,
            emailReseller,
            password
          ) ||
          (role === "admin" && !userRole.length)
        }
      >
        Add New User
      </Button>
    </>
  );

  return (
    <>
      <BasicDialogue
        show={show}
        handleClose={handleClose}
        title="Add New User"
        Content={Content}
        Action={Action}
      />
    </>
  );
}
