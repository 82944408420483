// @ts-nocheck
import React, { useState } from "react";
import { Tooltip, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExtendLicenses from "./modal/ExtendLicenses";
import AddChannels from "./modal/AddChannels";
import HistoryModal from "./modal/HistoryModal";
import { useAppStore } from "../AppStore";

export default function CustumMenus({
  element,
  handlerEnableChange,
  editDays,
  editChannels,
  extendActivatedExpired,
  addChannelsActivatedExpired,
  hasHistory,
  showIntegrators,
}) {
  const [VALUE] = useAppStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openExtendModal, setOpenExtendModal] = useState(false);
  const [openAddChannelsModal, setOpenAddChannelsModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const userRole = VALUE.currentUser.role;

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlerExtendModal = () => {
    setOpenExtendModal((prev) => !prev);
  };

  const handlerAddChannelsModal = () => {
    setOpenAddChannelsModal((prev) => !prev);
  };

  const handlerHistoryModa = () => {
    setOpenHistoryModal((prev) => !prev);
  };

  const {
    id,
    first_name,
    last_name,
    is_enabled,
    active,
    trial,
    license_id,
    status,
    has_children,
  } = element;

  const isEnabled = is_enabled || active;
  const isTrialLicense = trial;
  const licenseId = license_id;
  const isExpired = status === "expired";
  const isActivated = status === "activated";
  const canExtendLicense = (isActivated || isExpired) && !isTrialLicense;
  const activeLicense = isActivated && !isTrialLicense;
  const isAdmin = userRole === "admin";
  const hasChildren = has_children;
  const canSeeIntegrators = isAdmin && hasChildren;

  return (
    <>
      <Tooltip title="More">
        <IconButton color="primary" onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
        {!isExpired && (
          <MenuItem
            onClick={() => {
              handlerEnableChange(id, isEnabled);
              handleClose();
            }}
          >
            {isEnabled ? "Suspend" : "Resume"}
          </MenuItem>
        )}
        {canExtendLicense && (
          <MenuItem onClick={handlerExtendModal}>Extend</MenuItem>
        )}
        {activeLicense && (
          <MenuItem onClick={handlerAddChannelsModal}>Add Channels</MenuItem>
        )}
        {hasHistory && (
          <MenuItem onClick={handlerHistoryModa}>History</MenuItem>
        )}
        {canSeeIntegrators && (
          <MenuItem onClick={() => showIntegrators(id, first_name, last_name)}>
            Integrators List
          </MenuItem>
        )}
        <ExtendLicenses
          show={openExtendModal}
          handleClose={() => {
            handlerExtendModal();
            handleClose();
          }}
          extendActivatedExpired={extendActivatedExpired}
          item={element}
        />
        <AddChannels
          show={openAddChannelsModal}
          handleClose={() => {
            handlerAddChannelsModal();
            handleClose();
          }}
          addChannelsActivatedExpired={addChannelsActivatedExpired}
          item={element}
        />
        {openHistoryModal && (
          <HistoryModal
            show={openHistoryModal}
            handleClose={() => {
              handlerHistoryModa();
              handleClose();
            }}
            licenseId={licenseId}
          />
        )}
      </Menu>
    </>
  );
}
